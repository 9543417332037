import React, {useState, useEffect} from 'react';
export default function CountDownClock()
{
  let [minutes, changeMinutes] = useState(2);
  let [seconds, changeSeconds] =useState(0);
  let [toggleState, changeState] = useState(false);
  const setToggleToTrue = e =>
  {
    changeState(true);
  }
  const setToggleToFalse = e =>
  {
    changeState(false);
  }
  useEffect(_ => {
    const interval = setInterval(_ => {
      if(toggleState === true)
      {
        changeSeconds(seconds - 1);
        if(seconds <= 1)
        {
          changeSeconds(59);
          changeMinutes(minutes-1);
          if(minutes <= 0)
          {
            changeMinutes(0);
            changeSeconds(0);
            changeState(false);
          }
          
        }
      }

    }, 1000);
    return _ => {
      clearInterval(interval);
    }
  });
  return(
    <div>
      <h1>Countdown clock</h1>
      <span>{minutes} <span id="min">m</span></span>
      <span>:</span>
      <span>{seconds} <span id="sec">sec</span></span>
      <div id="btns">
        <button onClick={setToggleToTrue}>Start</button>
        <button onClick={setToggleToFalse}>Stop</button>
      </div>
    </div>
  );
}