import React from "react";
import "./styles.css";
import Timer from "./Timer";
import CountDownClock from "./CountDownClock";

export default function App() {
  return (

    <>
    <Timer />
    <CountDownClock />
    </>
  );
}
