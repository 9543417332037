import React, {useState, useEffect} from 'react';
export default function Timer()
{
  let [minutes, changeMinutes] = useState(0);
  let [seconds, changeSeconds] = useState(0);
  let [toggleClock, changeState] = useState(false);
  const setToggleToTrue = e =>
  {
    changeState(true);
  }
  const setToggleToFalse =e =>
  {
    changeState(false);
  }
  
  useEffect(_ => {
      if(toggleClock === true)
      {
        const intervalToken = setInterval(_ => {
          changeSeconds(seconds+1);
          if(seconds === 59)
          {
            changeMinutes(minutes+1);
            changeSeconds(0);
          }
        }, 1000);
        return _ => {
          clearInterval(intervalToken);
        };
      }
      
    });
   
    
  
  return (
    <div>
      <h1>Timer</h1>
      <span>{minutes} <span id="min">m</span></span>
      <span>:</span>
      <span >{seconds} <span id="sec">sec</span></span>
      <div id="btns">
        <button onClick={setToggleToTrue}>Start</button>
        <button onClick={setToggleToFalse}>Stop</button>
      </div>
      
    </div>
    
  );

}